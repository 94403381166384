@import "variables.scss";

ion-item {
    // globaly remove bottom border from ion-item
    --border-width: 0;
    --inner-border-width: 0;
    --padding-start: 0;
    --inner-padding-start: 0;
    --inner-padding-end: 0;
    --color: #{$input-placeholder-color};
    --background-focused: transparent;
    padding: 0 0 $global-padding*1.5;
    font-size: 100%;

    ion-label.label-floating {
        line-height: 200%;
        font-size: 125% !important;
    }

    ion-label.label-fixed {
        min-width: 176px !important;
        line-height: 200%;
        font-size: 100% !important;
    }
}

ion-input.sc-ion-input-ios-h {
    // global setup for inputs
    --background: #{$input-background};
    --color: #{$input-color};
    --placeholder-color: #{$input-placeholder-color};
    --placeholder-font-style: italic;
    --padding-start: 0 !important;
    --overflow: visible;
    border: 1px solid $input-border-color;
    border-radius: $global-radius;

    input.native-input {
        padding: 0 $global-padding;
        line-height: $input-height;
        height: $input-height;
        font-size: 100%;
        overflow: visible;
    }

    [readonly] {
        background: #ccc;
        opacity: 0.7;
    }

    [disabled] {
        background: #fefefe !important;
        opacity: 0.7 !important;
    }

    &.has-focus {
        border: 1px solid var(--ion-color-primary);
    }

    &.ion-touched {
        &.ion-invalid {
            border: 1px solid var(--ion-color-danger);
        }

        &.has-focus.ion-invalid {
            border: 1px solid var(--ion-color-warning);
        }
    }
}

ion-datetime {}

ion-select {
    // global setup for inputs
    background: #{$input-background};
    color: #{$input-color};
    --placeholder-color: #{$input-placeholder-color};
    --placeholder-font-style: italic;
    //--padding-start: $global-padding;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    overflow: visible;
    border: 1px solid $input-border-color;
    border-radius: $global-radius;
    min-width: 288px;
    padding: $global-padding - 1px;
    padding-right: $global-padding;
    padding-left: $global-padding;

    &.filter-select {
        color: var(--ion-color-secondary);
        width: 48px;
        float: left;
        font-size: 130%;
        text-align: right;
        background: none;
        min-width: 0;
        box-shadow: none;
        border: none;
        padding: 6px 0;

        .select-icon-inner {
            margin-top: 0;
        }
    }

    &.has-focus {
        border: 1px solid var(--ion-color-primary);
    }

    &.ion-touched {
        &.ion-invalid {
            border: 1px solid var(--ion-color-danger);
        }

        &.has-focus.ion-invalid {
            border: 1px solid var(--ion-color-warning);
        }
    }

    @media (max-width: 1366px) {
        min-width: 192px;
    }

    @media (max-width: $breakpoint-xl) {
        min-width: 220px;
    }
}

// IE/EDGE support --> adding important...
ion-checkbox {
    border-radius: $global-radius !important;
    --border-radius: var(--global-radius) !important;

    &.required.ion-touched {
        border: 1px solid var(--ion-color-danger);

        &.checkbox-checked {
            border: 1px solid $input-border-color;
        }
    }
}

ion-textarea.sc-ion-textarea-ios-h {
    // global setup for textarea
    --background: #{$input-background};
    --color: #{$input-color};
    --placeholder-color: #{$input-placeholder-color};
    --placeholder-font-style: italic;
    --padding-start: 0 !important;
    border: 1px solid $input-border-color;
    border-radius: $global-radius;

    [readonly] {
        background: #ccc;
        opacity: 0.7;
    }

    textarea.native-textarea {
        padding: $global-padding;
        //line-height: 1.2;
        height: 100%;
        font-size: 100%;
        min-height: $input-height*2.33;
    }
}

.has-error {

    ion-input.sc-ion-input-ios-h,
    ion-textarea {
        border: 1px solid $danger-color !important;
    }

    ion-datetime {
        border: 1px solid $danger-color;
    }
}

.error-message {
    width: 100%;
    padding-right: -$global-padding;
    position: absolute;
    z-index: 9999999;
    right: $global-padding;
    margin-top: -$global-padding*1.2;
    display: block;
    color: var(--ion-color-danger);
    text-align: right;
    font-size: 72%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.no-margin-top {
        margin-top: 0;
    }
}

@supports (-ms-ime-align:auto) {
    .searchbar-search-icon.sc-ion-searchbar-ios {
        top: 9px !important;
    }

    input::placeholder,
    textarea::placeholder {
        font-weight: normal !important;
    }
}

p-calendar {
    width: 100%;

    .p-calendar {
        width: 100%;

        .p-inputtext {
            height: 48px;
        }

        &.p-calendar-w-btn {
            border: 1px solid $input-border-color;

            input {
                font-size: 16px;
                color: #404040 !important;
                padding: 0 16px;
                border: none;
                width: 80%;
                height: 48px;
            }

            .p-datepicker-trigger.p-button {
                height: 48px;
            }
        }
    }

    .ui-calendar-w-btn {
        background: $input-background;
        border: 1px solid $input-border-color;
        border-radius: $global-radius;
        width: 100%;
        height: calc($input-height + 2px);

        input {
            font-size: $default-font-size;
            color: $input-color !important;
            padding: 0 $global-padding;
            border: none;
            width: 80%;
            height: $input-height;
        }

        .ui-button,
        .ui-button:hover {
            position: absolute;
            right: 0;
            background: $input-background;
            border-color: $input-border-color;
            border: none;
            color: $input-color;
        }
    }
}

.ui-datepicker {
    a.ui-state-default.ui-state-active {
        background-color: $primary-color !important;
    }
}